<template>
  <!-- <van-nav-bar title="缴费列表"></van-nav-bar> -->
  <div class="top">
    <div class="container">
      <div style="height: 10px"></div>
      <div class="student_info">
        <div class="info">
          <van-button
            class="changeBtn"
            plain
            size="mini"
            type="primary"
            @click="toChild"
            color="#1989fa"
            >切换</van-button
          >
          <img src="../assets/default_profile.png" />
          <div class="student_desc">
            <span @click="toChangeChildName"
              >{{ child_name }} <van-icon name="edit"
            /></span>
            <span>{{ class_name }}</span>
            <span>{{ school_name }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <van-tabs v-model:active="active" type="card"> -->
    <van-tabs v-model:active="active" color="#1989fa" @click-tab="onClickTab">
      <van-tab title="待缴费" name="1"></van-tab>
      <van-tab title="缴费成功" name="2"></van-tab>
      <van-tab title="线下缴费" name="3"></van-tab>
    </van-tabs>
  </div>
  <van-empty v-if="listSize == 0" description="暂无内容" />
  <div v-if="listSize > 0" style="margin-bottom: 40px">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        offset="10"
        @load="onLoad"
      >
        <van-cell-group
          inset
          v-for="item in list"
          :key="item.charge_id"
          style="margin-top: 5px"
          @click="toDetail(item)"
        >
          <van-cell :title="item.create_at" :value="payText" />
          <van-cell :title="item.charge_name" :value="'¥' + item.payment" />
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>
  <van-dialog
    v-model:show="show"
    title="修改学生姓名"
    show-cancel-button
    :before-close="beforeClose"
  >
    <van-cell-group inset>
      <van-field
        v-model="childName"
        maxlength="8"
        label="学生姓名"
        :rules="[{ required: true }]"
        placeholder="请输入学生姓名"
      />
    </van-cell-group>
  </van-dialog>
</template>

<script>
import { onMounted, ref } from "vue";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import api from "../service/api";
import { getCookie, isAndroid, setCookie } from "../common/js/utils";
import { isLogin } from "../common/js/isloginUtil";
import { AESencrypt } from "../common/js/utils";
import { getStorage, setStorage } from "../common/js/storeUtil";

export default {
  setup() {
    const active = ref(0);
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    // const route = useRoute(); //获取到值
    const router = useRouter(); //路由传值
    const payType = ref(1);
    const child_id = ref("");
    const child_name = ref("");
    const school_name = ref("");
    const class_name = ref("");
    const currentPage = ref(1);
    const payText = ref("未缴费");
    const listSize = ref(1);
    const show = ref(false);
    const childName = ref("");
    const refreshing = ref(false);

    onMounted(() => {
      /* eslint-disable */
      // setTitle("缴费列表");
      //判断有没有绑定用户,绑定则继续,没有则跳到绑定用户页面
      if (isLogin()) {
        let student = getStorage("student");
        child_id.value = student.id;
        child_name.value = student.name;
        school_name.value = student.school_name;
        class_name.value = student.class_name;
      } else {
        toBindUser();
      }
    });

    //去绑定用户页面
    const toBindUser = () => {
      router.push({
        path: "/",
        // query: {
        //   payment_id: item.payment_id,
        //   payType: payType.value,
        // },
      });
    };

    const onRefresh = () => {
      currentPage.value = 1;
      api
        .getChargeList({
          student_id: child_id.value,
          status: payType.value, //1待缴费，2缴费成功，3线下缴费
          page: currentPage.value,
        })
        .then((res) => {
          refreshing.value = false;
          if (res.data.code == 1001) {
            if (currentPage.value == 1) {
              list.value = [];
            }
            let result = res.data.result;
            let payList = result.data;
            for (let i = 0; i < payList.length; i++) {
              list.value.push(payList[i]);
            }
            listSize.value = list.value.length;
            // 加载状态结束
            currentPage.value++;
            loading.value = false;
            // 数据全部加载完成
            if (list.value.length >= result.total) {
              finished.value = true;
            }
          } else {
            listSize.value = 0;
            loading.value = false;
            finished.value = true;
          }
        })
        .catch((err) => {
          loading.value = false;
          finished.value = true;
          listSize.value = 0;
          Toast(err.message);
        });
    };

    /**
     * 更改孩子姓名
     */
    const toChangeChildName = () => {
      show.value = true;
    };

    const beforeClose = (action) => {
      if (action === "confirm") {
        if (!checkName(childName.value)) {
          Toast("姓名只能是2-8位汉字");
          return;
        }
        updateChildName();
      } else {
        show.value = false;
      }
    };
    
    function checkName(name) {
      var reg = /^[\u4e00-\u9fa5]{2,8}$/;
      return reg.test(name);
    }

    //更改学生姓名
    function updateChildName() {
      api
        .updateStudentName({
          student_id: child_id.value,
          student_name: childName.value,
        })
        .then((res) => {
          if (res.data.code == 1001) {
            show.value = false;
            Toast("更新成功");
            child_name.value = childName.value;
            // setStorage("childName", childName.value);
            let student = getStorage("student");
            student.name = childName.value;
            //更新成功后更新student和students
            setStorage("student", student);
            let students = getStorage("students");
            students = students.map(function (obj) {
              if (obj.id === student.id) {
                return student;
              }
              return obj;
            });
            setStorage("students", students);
          } else {
            Toast(res.data.message);
          }
        })
        .catch((err) => {
          Toast(err.message);
        });
    }
    /**
     * 第一个字符用*号替换
     * @param {*} str
     */
    const safeName = (str) => {
      if (str) {
        return str.replace(str.substring(0, 1), "*");
      } else {
        return "";
      }
    };

    //跳转到详情
    const toDetail = (item) => {
      router.push({
        path: "/detail",
        query: {
          charge_student_id: item.id,
          payType: payType.value,
        },
      });
    };

    const toChild = () => {
      history.pushState({}, "", `http://${window.location.host}`);
      router.push({
        path: "/child",
      });
    };

    const onClickTab = ({ name }) => {
      payType.value = name;
      currentPage.value = 1;
      switch (payType.value) {
        case "1":
          payText.value = "未缴费";
          break;
        case "2":
          payText.value = "缴费成功";
          break;
        case "3":
          payText.value = "线下缴费";
          break;
        default:
          payText.value = "";
          break;
      }
      onRefresh();
    };

    const onLoad = () => {
      /**
       * code 不为空时进行请求
       */
      finished.value = false;
      api
        .getChargeList({
          student_id: child_id.value,
          status: payType.value, //1待缴费，2缴费成功，3线下缴费
          page: currentPage.value,
        })
        .then((res) => {
          refreshing.value = false;
          if (res.data.code == 1001) {
            if (currentPage.value == 1) {
              list.value = [];
            }
            let result = res.data.result;
            let payList = result.data;
            for (let i = 0; i < payList.length; i++) {
              list.value.push(payList[i]);
            }
            listSize.value = list.value.length;
            // 加载状态结束
            currentPage.value++;
            loading.value = false;
            // 数据全部加载完成
            if (list.value.length >= result.total) {
              finished.value = true;
            }
          } else {
            listSize.value = 0;
            loading.value = false;
            finished.value = true;
          }
        })
        .catch((err) => {
          loading.value = false;
          finished.value = true;
          listSize.value = 0;
          Toast(err.message);
        });
    };

    // function getQuery(variable) {
    //   var query = window.location.search.substring(1);
    //   var vars = query.split("&");
    //   for (var i = 0; i < vars.length; i++) {
    //     var pair = vars[i].split("=");
    //     if (pair[0] == variable) {
    //       return decodeURI(pair[1]);
    //     }
    //   }
    //   return false;
    // }

    return {
      list,
      onLoad,
      loading,
      finished,
      toDetail,
      active,
      toChild,
      onClickTab,
      payType,
      child_id,
      child_name,
      school_name,
      class_name,
      currentPage,
      payText,
      listSize,
      safeName,
      show,
      childName,
      toChangeChildName,
      beforeClose,
      refreshing,
      onRefresh,
    };
  },
};
</script>

<style lang="less">
.top {
  position: sticky;
  top: 0;
  z-index: 99;
  .container {
    height: 140px;
    background-color: #f2f2f2;
    .student_info {
      width: 94%;
      margin: 0 auto;
      height: 120px;
      border-radius: 6px;
      background: linear-gradient(to right, #1989fa, #3794fc);
      // background-color: #1989fa;
      box-shadow: 0 2px 5px #1989fa;

      .info {
        display: block;
        width: 92%;
        height: 120px;
        margin: 0 auto;
        .changeBtn {
          float: right;
          margin-top: 20px;
          margin-right: 10px;
        }

        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-top: 4px;
          display: inline-block;
          overflow: hidden;
          margin-top: 30px;
        }
        .student_desc {
          display: inline-block;
          margin-left: 10px;
          line-height: 20px;
          font-size: 14px;
          color: #fff;
          span {
            display: block;
            color: #fff;
            font-size: 14px;
            padding: 2px 0;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
